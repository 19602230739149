import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from "rxjs";

@Injectable()
export class ApiClient {
    private baseUrl = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

    public get<T>(path): Observable<T> {
        const url = this.baseUrl + path;
        return this.http.get<T>(url, this.getRequestOptions())
    }

    public delete(path): Observable<void> {
        const url = this.baseUrl + path;
        return this.http.delete<void>(url, this.getRequestOptions())
    }

    private getRequestOptions() {
        const headers = {};
        headers['Content-Type'] = 'application/json';

        return {headers: new HttpHeaders(headers)};
    }
}
