import {Injectable} from '@angular/core';
import {ApiClient} from '../support/apiClient.service';
import {Observable} from 'rxjs';

@Injectable()
export class MarcoAccountsService {

    basePath = '/marco/accounts';

    constructor(private apiClient: ApiClient) {
    }

    public getMyself(): Observable<any> {
        const path = `${this.basePath}/me`;
        return this.apiClient.get(path);
    }

}
