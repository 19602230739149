import {inject, Injectable} from '@angular/core';
import LocalManager from '../support/localManager';
import {BehaviorSubject, EMPTY, mergeMap, Observable, tap} from 'rxjs';
import {OauthService} from './oauth.service';
import {BlUsersService} from './blocklabs/blUsersService.service';
import {Finboot, WorkspaceService} from './workspace.service';
import {MarcoAccountsService} from './marcoAccountsService.service';

@Injectable({
    providedIn: 'root',
})
export class PrincipalAuthService {
    private activeSession: any = {};
    private sessionSubject = new BehaviorSubject<any>(undefined);
    private localManager = inject(LocalManager)
    private authHelper = inject(OauthService)
    private blUsersService = inject(BlUsersService)
    private workspaceService = inject(WorkspaceService)
    private marcoService = inject(MarcoAccountsService)

    constructor() {
        if (this.authHelper.isAuthenticated()) {
            this.initialize();
        }
    }

    getActiveSession(): any {
        return this.activeSession;
    }

    session(): Observable<any> {
        return this.sessionSubject.asObservable()
    }


    initialize(): void {
        this.activeSession = {};
        this.saveActiveSession();
        this.loadLoggedSession();
    }

    private loadLoggedSession(): void {
        this.marcoService.getMyself().pipe(
            tap(user => {
                this.activeSession.user = user;
                this.sessionSubject.next(this.activeSession);
            }),
            mergeMap(() => {
                // Only call blUsersService if workspace isn't already set
                if (!this.localManager.getActiveWorkspace()) {
                    return this.blUsersService.getMyself();
                } else {
                    return EMPTY; // Skip the second call completely
                }
            }),
        ).subscribe(
            user => {
                const workspace = user.facility.workspace.workspaceId === Finboot ? 1 : 0;
                this.workspaceService.setWorkspace(workspace);
            },
        );
    }


    clearSession() {
        this.activeSession = null;
        this.saveActiveSession();
        this.sessionSubject.next(null);

    }

    private saveActiveSession(): void {
        this.localManager.setActiveSession(this.activeSession);
    }
}
